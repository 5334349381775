<template>
  <div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-end py-1">
        <b-col md="8" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack m-0 p-0">
            Math with Max Carter.
          </h3>
          <p class="font-weight-bold text-colorGray mb-50">Code4545</p>
          <h4 class="font-weight-bolder text-colorBlack mt-3 mb-2">
            Class Description
          </h4>
          <p class="font-weight-bold text-colorGray mb-50">
            Class Introduction and areas to be covered in session(s).
          </p>
        </b-col>
        <b-col md="4" class="text-right pr-3 mt-50">
          <b-link :to="{ name: 'Lecture' }">
            <img src="@/assets/images/icons/lecture/cross.svg" img-fluid />
          </b-link>
        </b-col>
      </b-row>
      <div class="px-2">
        <p class="px-50 font-weight-bold text-colorGray">
          JavaScript is the most popular scripting language in the world. It is
          responsible for the interactivity like games, online forms or surveys
          and other activities user’s can engage with on a website. Node.js is
          open source, completely free, and used by thousands of developers
          around the world. Node was built with JavaScript so it helps if you
          have some understanding of how JavaScript actually works.
        </p>
        <h4 class="font-weight-bolder text-colorBlack mt-3 mb-2">
          What You Will Learn Include:
        </h4>
        <ul class="font-weight-normal text-colorGray pb-3">
          <li>Synchronous and Asynchronous</li>
          <li>Creating your first Nodejs App</li>
          <li>Modules and Packages</li>
          <li>Creating and App with Express Generator</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style></style>
